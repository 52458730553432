import { UserData } from '@/interfaces/UserDataInterface'
import { useApiRepositories } from '@/composables/api/useApiRepositories'
import { useUserStore } from '@/store/useUserStore'
import { router } from '@/router'
import { ref } from 'vue'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useModal } from '@/composables/useModal'
import { useCookies } from '@/composables/api/useCookies'
import { googleLogout } from 'vue3-google-login'
import { useFathom } from '~/composables/fathom/useFathom'
import { useLocale } from '~/composables/useLocale'
import { useBuckets } from '~/composables/api/useBuckets'
import { useLlmLanguages } from '~/composables/api/useLlmLanguages'

const authenticated = ref<boolean>(
  window.localStorage.getItem('authenticated') !== null
)
const isRequesting = ref(false)
export const useAuth = () => {
  const { clearAvailablesLanguages } = useLlmLanguages()
  const { clearBuckets } = useBuckets()
  const { fathomOnSignUpEvent, fathomOnSignInEvent } = useFathom()
  const { locale } = useLocale()
  const updatedAuthenticated = () => {
    authenticated.value = window.localStorage.getItem('authenticated') !== null
  }

  window.onstorage = () => {
    updatedAuthenticated()
  }

  const { deleteBucketModal } = useModal()
  const { clearAllCookies } = useCookies()
  const store = useUserStore()
  const { fetchUser, clearUser } = store
  const { user, userLogWithSso } = storeToRefs(store)
  const { userRepository } = useApiRepositories()
  const registerUser = async (registerData: UserData) => {
    try {
      isRequesting.value = true
      const result = await userRepository.register(registerData, null)
      if (result.status === 201) {
        fathomOnSignUpEvent()
        setAuthenticated(true)
        await fetchUser()
        await router.push({
          path: `/${locale.value}/home`,
          query: { shouldValidate: 1 },
        })
        userLogWithSso.value = false
      }
    } catch (error) {
      isRequesting.value = false
      console.log('Unable to register user', error)
    } finally {
      isRequesting.value = false
    }
  }

  const loginUser = async (loginData: UserData) => {
    try {
      isRequesting.value = true
      const result = await userRepository.login(
        loginData.email,
        loginData.password
      )
      if (result) {
        setAuthenticated(true)
        fathomOnSignInEvent()
        await fetchUser()
        // await router.push({ path: `/${locale.value}/home` })
        await router.push({
          path:
            (router.currentRoute.value.query.redirect as string) ||
            `/${locale.value}/home`,
        })
        userLogWithSso.value = false
      }
    } catch (error) {
      isRequesting.value = false
      console.log('Unable to login user', error)
    } finally {
      isRequesting.value = false
    }
  }

  const resendEmailVerification = async () => {
    try {
      await userRepository.resendEmailVerification(user.value.email)
    } catch (error) {
      console.log('unable to send email', error)
    }
  }

  const logout = async () => {
    try {
      const response = await userRepository.logout()
      if (response) {
        clearAvailablesLanguages()
        clearAllCookies()
        clearUser()
        clearBuckets()
        setAuthenticated(false)
        googleLogout()
        router.push({ path: `/${locale.value}/auth/login` })
      }
    } catch (error) {
      console.log('Unable to logout user', error)
    }
  }

  const sendCodeToResetPassword = async (email: string) => {
    try {
      isRequesting.value = true
      await userRepository.forgetPassword(email)
    } catch (error) {
      console.log('unable to send code', error)
    } finally {
      isRequesting.value = false
    }
  }

  const resetAuthPassword = async (userData: UserData, token: string) => {
    const data: UserData = {
      password: userData.password,
      confirmPassword: userData.confirmPassword,
      email: userData.email,
    }
    try {
      isRequesting.value = true
      const result = await userRepository.resetPassword(data, token)

      if (result.status === 200) {
        await router.push({
          path: `/${locale.value}/auth/login`,
        })
      }
    } catch (e) {
      isRequesting.value = false
      console.log('unable to reset password', e)
    } finally {
      isRequesting.value = false
    }
  }

  const verifyEmail = async () => {
    const route = useRoute()
    try {
      const result = await userRepository.verifyEmail(
        route.query.expires,
        route.query.signature,
        route.query.id,
        route.query.hash
      )

      if (result.status === 204) {
        if (user.value !== null) {
          await fetchUser()
          return await router.push({
            path: `/${locale.value}/splashscreen`,
          })
        }

        await router.push({
          path: `/${locale.value}/auth/login`,
        })
      }
    } catch (e) {
      console.log('Unable to verify email', e)
    }
  }

  const deleteAccount = async () => {
    try {
      const deleted = await userRepository.deleteAccount()
      if (deleted) {
        deleteBucketModal.value = false

        logout()
      }
    } catch (error) {
      console.log('Unable to delete account', error)
    }
  }

  const setAuthenticated = (value: boolean) => {
    if (value) {
      window.localStorage.setItem('authenticated', '1')
    } else {
      window.localStorage.removeItem('authenticated')
    }

    authenticated.value = value
  }

  return {
    registerUser,
    authenticated,
    loginUser,
    logout,
    resendEmailVerification,
    sendCodeToResetPassword,
    resetAuthPassword,
    verifyEmail,
    deleteAccount,
    setAuthenticated,
    isRequesting,
  }
}
