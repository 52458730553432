const __pages_import_0__ = () => import("/src/pages/models/index.vue");
const __pages_import_1__ = () => import("/src/pages/insights/index.vue");
const __pages_import_2__ = () => import("/src/pages/auth/verify-email.vue");
const __pages_import_3__ = () => import("/src/pages/auth/reset-password.vue");
const __pages_import_4__ = () => import("/src/pages/auth/new-password.vue");
const __pages_import_5__ = () => import("/src/pages/splashscreen.vue");
const __pages_import_6__ = () => import("/src/pages/small-screen-notice.vue");
const __pages_import_7__ = () => import("/src/pages/profile.vue");
const __pages_import_8__ = () => import("/src/pages/home.vue");
const __pages_import_9__ = () => import("/src/pages/developer.vue");
const __pages_import_10__ = () => import("/src/pages/auth/_type.vue");
const __pages_import_11__ = () => import("/src/pages/insights/_id.vue");
const __pages_import_12__ = () => import("/src/pages/models/_slug.vue");
const __pages_import_13__ = () => import("/src/pages/auth/oauth/_slug/callback.vue");

const routes = [{"name":"models","path":"/:lang?//models","component":__pages_import_0__,"props":true,"children":[]},{"name":"insights","path":"/:lang?//insights","component":__pages_import_1__,"props":true,"children":[]},{"name":"auth-verify-email","path":"/:lang?//auth/verify-email","component":__pages_import_2__,"props":true,"meta":{"layout":"empty"},"children":[]},{"name":"auth-reset-password","path":"/:lang?//auth/reset-password","component":__pages_import_3__,"props":true,"meta":{"layout":"empty"},"children":[]},{"name":"auth-new-password","path":"/:lang?//auth/new-password","component":__pages_import_4__,"props":true,"meta":{"layout":"empty"},"children":[]},{"name":"splashscreen","path":"/:lang?//splashscreen","component":__pages_import_5__,"props":true,"meta":{"layout":"callbacklayout"},"children":[]},{"name":"small-screen-notice","path":"/:lang?//small-screen-notice","component":__pages_import_6__,"props":true,"meta":{"layout":"empty"},"children":[]},{"name":"profile","path":"/:lang?//profile","component":__pages_import_7__,"props":true,"children":[]},{"name":"home","path":"/:lang?//home","component":__pages_import_8__,"props":true,"children":[]},{"name":"developer","path":"/:lang?//developer","component":__pages_import_9__,"props":true,"children":[]},{"name":"auth-type","path":"/:lang?//auth/:type?","component":__pages_import_10__,"props":true,"meta":{"layout":"empty"},"children":[]},{"name":"insights-id","path":"/:lang?//insights/:id","component":__pages_import_11__,"props":true,"children":[]},{"name":"model","path":"/:lang?//models/:slug","component":__pages_import_12__,"props":true,"meta":{"layout":"empty"},"children":[]},{"name":"auth-oauth-slug-callback","path":"/:lang?//auth/oauth/:slug/callback","component":__pages_import_13__,"props":true,"meta":{"layout":"callbacklayout"},"children":[]}];

export default routes;