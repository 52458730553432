import { computed, ref } from 'vue'
import { useApiRepositories } from '@/composables/api/useApiRepositories'
import { useApiJsonValidator } from '@/composables/useApiJsonValidator'
import modelsValidator from '@/jsonValidator/modelsValidator.json'
import { Model } from '@/interfaces/ModelInterface'
import { PencilSquareIcon } from '@heroicons/vue/24/outline'
import { useIcons } from '~/composables/icons/useIcons'

const models = ref<Array<Model>>(null)
const loadingModels = ref<boolean>(false)
export const useModels = () => {
  const { modelsRepository } = useApiRepositories()
  const { validateData } = useApiJsonValidator()

  const clearModels = () => {
    models.value = null
  }

  const getModels = async () => {
    try {
      if (models.value) {
        return
      }
      loadingModels.value = true
      const result = (await modelsRepository.index())?.data
      if (result) {
        const isValidData = validateData(modelsValidator, result)
        if (isValidData) {
          models.value = result
          loadingModels.value = false
        }
      }
    } catch (error) {
      console.log('unable to get models', error)
    }
  }
  const { iconsMapping } = useIcons()
  const formattedModelsForSearchPalette = computed(() => {
    if (models.value && models.value.length > 0) {
      return models.value.map((model) => ({
        title: model.title,
        description: model.description,
        slug: model.slug,
        color: model.color ? `#${model.color}` : `#6D52D7`,
        icon: model.icon ? iconsMapping[model.icon] : PencilSquareIcon,
      }))
    }
    return []
  })

  const refreshModels = () => {
    clearModels()
  }

  return {
    getModels,
    models,
    loadingModels,
    refreshModels,
    formattedModelsForSearchPalette,
  }
}
